import { atom } from 'recoil'
import { Workspace } from 'vidbase-types/workspace'
import { RecoilAtomKeys } from '@/store/keys'

type WorkspaceState = {
  workspace: Workspace | undefined
  loading: boolean
  error: Error | undefined
}

const initialState: WorkspaceState = {
  workspace: undefined,
  loading: true,
  error: undefined,
}

export const workspaceState = atom<WorkspaceState>({
  key: RecoilAtomKeys.WORKSPACE_STATE,
  default: initialState,
  // https://scrapbox.io/study-react/Recoil_Hooks_%E3%81%8C_TypeError:_Cannot_freeze_%E3%82%A8%E3%83%A9%E3%83%BC%E3%82%92%E6%8A%95%E3%81%92%E3%82%8B
  dangerouslyAllowMutability: true,
})
