import {
  DocumentReference,
  doc,
  collection,
  Timestamp,
} from 'firebase/firestore'
import {
  WorkspaceUser,
  workspaceUserConverter,
} from 'vidbase-types/workspaceUser'
import { db } from '@/services/firebase/client'
import crudGenerator from '@/services/firebase/client/firestore/crudGenerator'

export const getWorkspaceUsersRef = (workspaceId: string) => {
  const ref = collection(db, 'workspaces', workspaceId, 'users')
  return ref
}

export const getWorkspaceUserRef = (workspaceId: string, uid?: string) => {
  const collectionRef = getWorkspaceUsersRef(workspaceId)
  const ref = uid ? doc(collectionRef, uid) : doc(collectionRef)
  return ref
}

export const {
  get: getWorkspaceUser,
  getList: getWorkspaceUsers,
  add: addWorkspaceUser,
  create: createWorkspaceUser,
  partialUpdate: partialUpdateWorkspaceUser,
  delete: deleteWorkspaceUser,
} = crudGenerator<
  WorkspaceUser<Timestamp, DocumentReference>,
  [workspaceId: string],
  [workspaceId: string, uid: string]
>(getWorkspaceUsersRef, getWorkspaceUserRef, workspaceUserConverter)
