export enum RecoilAtomKeys {
  AUTH_USER_STATE = 'authUserState',
  ME_STATE = 'meState',
  USERS_STATE = 'usersState',
  ROOT_ME_STATE = 'rootMeState',
  WORKSPACE_STATE = 'workspaceState',
  MESSAGES_STATE = 'messagesState',
  PORTAL_STATE = 'portalState',
  VIRTUAL_BACKGROUND_STATE = 'virtualBackgroundState',
}
