import { Base, getConverter } from './document'

/**
 * Marketo Interface
 * @typeparam {T} set timestamp type parameter
 * */
export interface Marketo<T = any> {
  isConnected: boolean
  engagementsSyncedAt?: T
}

export interface Pardot {
  isConnected: boolean
  isSandbox: boolean
  businessUnitId?: string
  tenants: Tenant[]
}

/**
 * Salesforce Interface
 * @typeparam {T} set timestamp type parameter
 * */
export interface Salesforce<T = any> {
  isConnected: boolean
  isSandbox: boolean
  engagementsSyncedAt?: T
  engagementsAnonymousMergedAt?: T
}

export interface Tenant {
  attributes: {
    type: string
    url: string
  }
  Id: string
  PardotTenantName: string
}

export interface Identity {
  tenantId?: string
  mfaRequired: boolean
}

/**
 * Firestore Document Workspace Interface
 * @typeparam {T} set timestamp type parameter
 * @typeparam {U} set DocumentReference type parameter
 * */
export interface Workspace<T = any, U = any> extends Base<T, U> {
  name: string
  marketo?: Marketo<T>
  pardot?: Pardot
  salesforce?: Salesforce<T>
  isPardotExternalActivityEnabled?: boolean
  identity: Identity
}

export const workspaceConverter = getConverter<Workspace>((snapshot, data) => {
  const workspace: Workspace = {
    id: snapshot.id,
    ref: snapshot.ref,
    ...data,
    identity: {
      tenantId: data.identity?.tenantId,
      mfaRequired: data.identity?.mfaRequired ?? false,
    },
  }
  return workspace
})
