import { DocumentReference, onSnapshot, Timestamp } from 'firebase/firestore'
import { useEffect } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { Workspace, workspaceConverter } from 'vidbase-types/workspace'
import { getWorkspaceRef } from '@/repositories/workspace'
import { authUserState } from '@/store/authUser'
import { workspaceState } from '@/store/workspace'

const useSyncWorkspace = (workspaceId: string | undefined) => {
  const { authUser } = useRecoilValue(authUserState)
  const setWorkspace = useSetRecoilState(workspaceState)

  useEffect(() => {
    if (!authUser || !workspaceId) {
      return
    }

    const targetRef = getWorkspaceRef(workspaceId)
    const unsubscribe = onSnapshot(
      targetRef.withConverter<Workspace<Timestamp, DocumentReference>>(
        workspaceConverter
      ),
      (snap) => {
        if (!snap.exists()) {
          setWorkspace({
            workspace: undefined,
            loading: false,
            error: Error('not found workspace'),
          })
          return
        }
        const workspace = snap.data()!
        setWorkspace({
          workspace,
          loading: false,
          error: Error('not found workspace'),
        })
      },
      (error) => {
        console.error(error)
        setWorkspace({ workspace: undefined, loading: false, error })
      }
    )

    return () => {
      unsubscribe()
    }
  }, [authUser, setWorkspace, workspaceId])
}

export default useSyncWorkspace
