import { useEffect } from 'react'
import { setUserProperties } from '@/services/amplitude/analytics'

interface AmplitudeProviderProps {
  workspaceId?: string
  workspaceName?: string
  userId?: string
  userName?: string
  children?: React.ReactNode
}

const AmplitudeProvider = ({
  workspaceId,
  workspaceName,
  userId,
  userName,
  children,
}: AmplitudeProviderProps) => {
  useEffect(() => {
    if (!workspaceId || !workspaceName || !userId || !userName) return
    setUserProperties({
      workspaceId,
      workspaceName,
      userId,
      userName,
    })
  }, [userId, userName, workspaceId, workspaceName])

  return <>{children}</>
}

export default AmplitudeProvider
