import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Toaster } from 'react-hot-toast'
import { RecoilRoot } from 'recoil'
import 'vidbase-ui/styles.css'
import { appName } from 'vidbase-utils/vidbase/domain'
import AmplitudeProvider from '@/components/shared/amplitudeProvider'
import SentryProvider from '@/components/shared/sentryProvider'
import VidbaseProvider from '@/components/shared/vidbaseProvider'
import { logEvent } from '@/services/amplitude/analytics'
import { NextPageWithLayout } from '@/types/nextPage'
import { initInterceptor } from '@/services/initInterceptor'

if (typeof window !== undefined) {
  initInterceptor()
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      logEvent('page_view', { page_path: url })
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <RecoilRoot>
      <DndProvider backend={HTML5Backend}>
        <Head>
          <title>{appName}</title>
          <link rel="icon" href="/favicon.ico" />
          <meta name="robots" content="noindex,nofollow" />
        </Head>
        <VidbaseProvider>
          {({ authUser, user, workspace }) => (
            <AmplitudeProvider
              workspaceId={workspace?.id}
              workspaceName={workspace?.name}
              userId={user?.id}
              userName={user?.name}
            >
              <SentryProvider
                userId={user?.id}
                userName={user?.name}
                email={authUser?.email ?? undefined}
              >
                <Toaster position="top-center" />
                {getLayout(<Component {...pageProps} />)}
              </SentryProvider>
            </AmplitudeProvider>
          )}
        </VidbaseProvider>
      </DndProvider>
    </RecoilRoot>
  )
}

export default App
