import { Workspace } from 'vidbase-types/workspace'
import { WorkspaceUser } from 'vidbase-types/workspaceUser'
import useSyncWorkspace from '@/hooks/workspace/useSyncWorkspace'
import useWorkspace from '@/hooks/workspace/useWorkspace'
import useSyncWorkspaceUsers from '@/hooks/workspaceUser/useSyncWorkspaceUsers'
import useWorkspaceUsers from '@/hooks/workspaceUser/useWorkspaceUsers'

interface WorkspaceProviderProps {
  workspaceId?: string
  children?: ({
    workspace,
    workspaceUsers,
  }: {
    workspace?: Workspace
    workspaceUsers?: WorkspaceUser[]
  }) => React.ReactNode
}

const WorkspaceProvider = ({
  workspaceId,
  children,
}: WorkspaceProviderProps) => {
  const [workspace] = useWorkspace()
  const [workspaceUsers] = useWorkspaceUsers()
  useSyncWorkspace(workspaceId)
  useSyncWorkspaceUsers(workspaceId)

  return <>{children && children({ workspace, workspaceUsers })}</>
}

export default WorkspaceProvider
