import { onAuthStateChanged, User as AuthUser } from 'firebase/auth'
import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { auth } from '@/services/firebase/client'
import { authUserState } from '@/store/authUser'

const useSyncAuthUser = () => {
  const setAuthUser = useSetRecoilState(authUserState)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (authUser: AuthUser | null) => {
        setAuthUser({
          authUser: authUser || undefined,
          loading: false,
          error: undefined,
        })
      },
      (error) => {
        setAuthUser({ authUser: undefined, loading: false, error })
      }
    )
    return () => {
      unsubscribe()
    }
  }, [setAuthUser])
}

export default useSyncAuthUser
