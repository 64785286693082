import { DocumentReference, Timestamp } from 'firebase/firestore'
import { useRecoilValue } from 'recoil'
import { User } from 'vidbase-types/user'
import { myUserState } from '@/store/myUser'

const useMyUser = (): [
  User<Timestamp, DocumentReference> | undefined,
  boolean,
  Error | undefined
] => {
  const { myUser, loading, error } = useRecoilValue(myUserState)
  return [myUser, loading, error]
}

export default useMyUser
