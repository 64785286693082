import { AxiosError } from 'axios'

export const standardAxiosError = (error: AxiosError<any>) => {
  const defaultErr = { status: 500, msg: 'Internal server error' }
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const { data, status } = error.response
    defaultErr.status = status
    defaultErr.msg = data.message
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    defaultErr.msg = 'Cannot send the request'
  }

  return defaultErr
}
