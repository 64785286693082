import { Base, getConverter } from './document'

export type Role = 'admin' | 'sales'

/**
 * Firestore Document WorkspaceUser Interface
 * @typeparam {T} set timestamp type parameter
 * @typeparam {U} set DocumentReference type parameter
 * */
export interface WorkspaceUser<T = any, U = any> extends Base<T, U> {
  name: string
  role: Role
}

export const workspaceUserConverter = getConverter<WorkspaceUser>()
