import {
  DocumentReference,
  doc,
  collection,
  Timestamp,
} from 'firebase/firestore'
import { User, userConverter } from 'vidbase-types/user'
import { db } from '@/services/firebase/client'
import crudGenerator from '@/services/firebase/client/firestore/crudGenerator'

export const getUsersRef = () => {
  const ref = collection(db, 'users')
  return ref
}

export const getUserRef = (uid?: string) => {
  const collectionRef = getUsersRef()
  const ref = uid ? doc(collectionRef, uid) : doc(collectionRef)
  return ref
}

export const {
  get: getUser,
  getList: getUsers,
  add: addUser,
  create: createUser,
  partialUpdate: partialUpdateUser,
  delete: deleteUser,
} = crudGenerator<User<Timestamp, DocumentReference>, [], [uid: string]>(
  getUsersRef,
  getUserRef,
  userConverter
)
