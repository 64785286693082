import { amplitudeClient } from '@/services/amplitude'

type Event =
  | 'page_view'
  | 'submit_sign_in'
  | 'click_sign_out'
  | 'click_add_video_contents'
  | 'click_add_video_file'
  | 'submit_youtube_url'
  | 'submit_video_file'
  | 'click_save_video_contents'
  | 'click_delete_video_contents'
  | 'click_create_campaign_url'
  | 'click_copy_campaign_url'
  | 'click_stream_video'
  | 'click_update_video_contents'
  | 'submit_update_video_contents'
  | 'submit_ma_integrations'
  | 'click_update_user_name'
  | 'click_copy_embed_code'
  | 'click_save_cta_button'
  | 'click_save_cta_form'
  | 'click_add_cta_button'
  | 'click_add_cta_form'
  | 'click_add_contact_url'
  | 'click_save_contact_url'
  | 'click_copy_contact_url'
  | 'click_create_schedule_url'
  | 'click_update_schedule_url'
  | 'click_save_schedule_url'
  | 'click_copy_schedule_url'
  | 'click_save_private_video_contents'
  | 'click_delete_webinar'
  | 'click_create_webinar'
  | 'click_update_webinar'
  | 'click_preview_campaign_url'

export const logEvent = (
  event: Event,
  eventParams?: {
    [key: string]: any
  }
) => {
  if (!amplitudeClient) return
  amplitudeClient.logEvent(event, eventParams)
}

type Properties = {
  workspaceId: string
  workspaceName: string
  userId: string
  userName: string
}

export const setUserProperties = ({
  workspaceId,
  workspaceName,
  userId,
  userName,
}: Properties) => {
  if (!amplitudeClient) return

  amplitudeClient.setUserProperties({
    workspace_id: workspaceId,
    workspace_name: workspaceName,
    user_id: userId,
    user_name: userName,
  })
}
