import {
  DocumentReference,
  doc,
  collection,
  Timestamp,
} from 'firebase/firestore'
import { Workspace, workspaceConverter } from 'vidbase-types/workspace'
import { db } from '@/services/firebase/client'
import crudGenerator from '@/services/firebase/client/firestore/crudGenerator'

export const getWorkspacesRef = () => {
  const ref = collection(db, 'workspaces')
  return ref
}

export const getWorkspaceRef = (workspaceId?: string) => {
  const collectionRef = getWorkspacesRef()
  return workspaceId ? doc(collectionRef, workspaceId) : doc(collectionRef)
}

export const {
  get: getWorkspace,
  getList: getWorkspaces,
  add: addWorkspace,
  create: createWorkspace,
  partialUpdate: partialUpdateWorkspace,
  delete: deleteWorkspace,
} = crudGenerator<
  Workspace<Timestamp, DocumentReference>,
  [],
  [workspaceId: string]
>(getWorkspacesRef, getWorkspaceRef, workspaceConverter)
