import { User as AuthUser } from 'firebase/auth'
import { User } from 'vidbase-types/user'
import { Workspace } from 'vidbase-types/workspace'
import { WorkspaceUser } from 'vidbase-types/workspaceUser'
import AuthUserProvider from '@/components/shared/vidbaseProvider/authUserProvider'
import UserProvider from '@/components/shared/vidbaseProvider/userProvider'
import WorkspaceProvider from '@/components/shared/vidbaseProvider/workspaceProvider'

interface VidbaseProviderProps {
  children: (props: {
    authUser?: AuthUser
    user?: User
    workspace?: Workspace
    workspaceUsers?: WorkspaceUser[]
  }) => React.ReactNode
}

const VidbaseProvider = ({ children }: VidbaseProviderProps) => {
  return (
    <AuthUserProvider>
      {({ authUser }) => (
        <UserProvider>
          {({ user }) => (
            <WorkspaceProvider workspaceId={user?.mainWorkspaceId}>
              {({ workspace, workspaceUsers }) =>
                children({ authUser, user, workspace, workspaceUsers })
              }
            </WorkspaceProvider>
          )}
        </UserProvider>
      )}
    </AuthUserProvider>
  )
}

export default VidbaseProvider
