import { Base, getConverter } from './document'

/**
 * Firestore Document User Interface
 * @typeparam {T} set timestamp type parameter
 * @typeparam {U} set DocumentReference type parameter
 * */
export interface User<T = any, U = any> extends Base<T, U> {
  mainWorkspaceId: string
  name?: string
  iconName?: string
}

export const userConverter = getConverter<User>((snapshot, data) => {
  let iconName = ''
  if (data.name?.length == 1) {
    iconName = data.name
  } else if (data.name?.length > 1) {
    iconName = (data.name[0] + data.name[1]).toUpperCase()
  }
  const content: User = {
    id: snapshot.id,
    ref: snapshot.ref,
    iconName,
    ...data,
  }
  return content
})
