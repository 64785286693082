import { atom } from 'recoil'
import { WorkspaceUser } from 'vidbase-types/workspaceUser'
import { RecoilAtomKeys } from '@/store/keys'

type WorkspaceUsersState = {
  workspaceUsers: WorkspaceUser[]
  loading: boolean
  error: Error | undefined
}

const initialState: WorkspaceUsersState = {
  workspaceUsers: [],
  loading: true,
  error: undefined,
}

export const workspaceUsersState = atom<WorkspaceUsersState>({
  key: RecoilAtomKeys.USERS_STATE,
  default: initialState,
  // https://scrapbox.io/study-react/Recoil_Hooks_%E3%81%8C_TypeError:_Cannot_freeze_%E3%82%A8%E3%83%A9%E3%83%BC%E3%82%92%E6%8A%95%E3%81%92%E3%82%8B
  dangerouslyAllowMutability: true,
})
