import { DocumentReference, onSnapshot, Timestamp } from 'firebase/firestore'
import { useEffect } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { User, userConverter } from 'vidbase-types/user'
import { getUserRef } from '@/repositories/user'
import { authUserState } from '@/store/authUser'
import { myUserState } from '@/store/myUser'

const useSyncMyUserSubscribe = () => {
  const { authUser } = useRecoilValue(authUserState)
  const setMyUser = useSetRecoilState(myUserState)

  useEffect(() => {
    if (!authUser) {
      return
    }

    const targetRef = getUserRef(authUser.uid)
    const unsubscribe = onSnapshot(
      targetRef.withConverter<User<Timestamp, DocumentReference>>(
        userConverter
      ),
      (snap) => {
        if (!snap.exists()) {
          setMyUser({ myUser: undefined, loading: false, error: undefined })
          return
        }
        const user = snap.data()!
        setMyUser({ myUser: user, loading: false, error: undefined })
      },
      (error) => {
        console.error(error)
        setMyUser({ myUser: undefined, loading: false, error })
      }
    )

    return () => {
      unsubscribe()
    }
  }, [authUser, setMyUser])
}

export default useSyncMyUserSubscribe
