import { User } from 'vidbase-types/user'
import useMyUser from '@/hooks/user/useMyUser'
import useMyUserSubscribe from '@/hooks/user/useSyncMyUserSubscribe'

interface UserProviderProps {
  children?: ({ user }: { user?: User }) => React.ReactNode
}

const UserProvider = ({ children }: UserProviderProps) => {
  const [user] = useMyUser()
  useMyUserSubscribe()

  return <>{children && children({ user })}</>
}

export default UserProvider
