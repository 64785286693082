import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'

interface SentryProviderProps {
  userId?: string
  userName?: string
  email?: string
  children?: React.ReactNode
}

const SentryProvider = ({
  userId,
  userName,
  email,
  children,
}: SentryProviderProps) => {
  useEffect(() => {
    if (!userId) return
    Sentry.setUser({
      id: userId,
      username: userName || '',
      email: email || '',
    })
  }, [email, userId, userName])

  return <>{children}</>
}

export default SentryProvider
