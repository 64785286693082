import { DocumentReference, Timestamp } from 'firebase/firestore'
import { useRecoilValue } from 'recoil'
import { WorkspaceUser } from 'vidbase-types/workspaceUser'
import { workspaceUsersState } from '@/store/workspaceUsers'

const useWorkspaceUsers = (): [
  WorkspaceUser<Timestamp, DocumentReference>[],
  boolean,
  Error | undefined
] => {
  const { workspaceUsers, loading, error } = useRecoilValue(workspaceUsersState)
  return [workspaceUsers, loading, error]
}

export default useWorkspaceUsers
