import { DocumentReference, onSnapshot, Timestamp } from 'firebase/firestore'
import { useEffect } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import {
  WorkspaceUser,
  workspaceUserConverter,
} from 'vidbase-types/workspaceUser'
import { getWorkspaceUsersRef } from '@/repositories/workspaceUser'
import { authUserState } from '@/store/authUser'
import { workspaceUsersState } from '@/store/workspaceUsers'

const useSyncWorkspaceUsers = (workspaceId: string | undefined) => {
  const { authUser } = useRecoilValue(authUserState)
  const setWorkspaceUsers = useSetRecoilState(workspaceUsersState)

  useEffect(() => {
    if (!authUser || !workspaceId) {
      return
    }

    const targetRef = getWorkspaceUsersRef(workspaceId)
    const unsubscribe = onSnapshot(
      targetRef.withConverter<WorkspaceUser<Timestamp, DocumentReference>>(
        workspaceUserConverter
      ),
      (snap) => {
        const workspaceUsers = snap.docs.map((doc) => doc.data())
        setWorkspaceUsers({ workspaceUsers, loading: false, error: undefined })
      },
      (error) => {
        console.error(error)
        setWorkspaceUsers({ workspaceUsers: [], loading: false, error })
      }
    )

    return () => {
      unsubscribe()
    }
  }, [authUser, setWorkspaceUsers, workspaceId])
}

export default useSyncWorkspaceUsers
