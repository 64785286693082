import {
  CollectionReference,
  DocumentReference,
  FirestoreDataConverter,
} from 'firebase/firestore'
import { Base } from 'vidbase-types/document'
import {
  getDocument,
  createDocument,
  partialUpdateDocument,
  MutationDocumentData,
  addDocument,
  getDocuments,
  deleteDocument,
} from '@/repositories/document'

/**
 * Generate CRUD functions
 * @typeparam {T} set domain type
 * @typeparam {U} set getCollectionRef args. ex.) [workspaceId: string, embedId: string]
 * @typeparam {V} set getDocumentRef args. ex.) [workspaceId: string, embedId: string, engagementId: string]
 * @param {unknown} getCollectionRef get CollectionReference function
 * @param {unknown} getDocumentRef get DocumentReference function
 * @param {unknown} converter converter function
 * @return {unknown} crud funcitons
 * */
const crudGenerator = <T extends Base, U extends string[], V extends string[]>(
  getCollectionRef: (...args: U) => CollectionReference,
  getDocumentRef: (...args: V) => DocumentReference,
  converter: FirestoreDataConverter<T>
) => {
  const get = async (...path: V) => {
    const ref = getDocumentRef(...path)
    return await getDocument<T>(ref, converter)
  }

  const getList = async (...path: U) => {
    const ref = getCollectionRef(...path)
    return await getDocuments<T>(ref, converter)
  }

  const add = async (path: U, data: MutationDocumentData<T>) => {
    const ref = getCollectionRef(...path)
    return await addDocument<T>(ref, data)
  }

  const create = async (path: V, data: MutationDocumentData<T>) => {
    const ref = getDocumentRef(...path)
    return await createDocument<T>(ref, data)
  }

  const partialUpdate = async (
    path: V,
    data: Partial<MutationDocumentData<T>>
  ) => {
    const ref = getDocumentRef(...path)
    return await partialUpdateDocument<T>(ref, data)
  }

  // MEMO: 'delete' is not allowed as a variable declaration name.
  const _delete = async (...path: V) => {
    const ref = getDocumentRef(...path)
    return await deleteDocument(ref)
  }

  return { get, getList, add, create, partialUpdate, delete: _delete }
}

export default crudGenerator
