import { standardAxiosError } from '@/utils/axiosError'
import axios from 'axios'
import Sentry from '@sentry/nextjs'

export const initInterceptor = () => {
  axios.interceptors.response.use(
    (res) => {
      return res
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      const convertErrObj = standardAxiosError(error)
      console.error('Original axios error:', error)

      Sentry.setContext('Client side request failed', {
        lib: 'axios',
        from: 'client',
      })
      Sentry.captureException(error)

      return Promise.reject(convertErrObj)
    }
  )
}
