/**
 * Firestore Document Base Interface
 * @typeparam {T} set timestamp type parameter
 * @typeparam {U} set DocumentReference type parameter
 * */
export interface Base<T = any, U = any> {
  id: string
  ref: U
  createdAt: T
  updatedAt: T
}

export interface Snapshot {
  data: () => any
  id: any
  ref: any
}

export const getConverter = <T extends Base>(
  transpiler: (snapshot: Snapshot, data: any) => T = (snapshot, data) => {
    return {
      id: snapshot.id,
      ref: snapshot.ref,
      ...data,
    }
  }
) => {
  const converter = {
    toFirestore(data: T) {
      const { ...rest } = data
      return rest
    },
    fromFirestore(snapshot: Snapshot) {
      const data = snapshot.data()
      const value: T = transpiler(snapshot, data)
      return value
    },
  }
  return converter
}
