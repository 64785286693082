import { DocumentReference, Timestamp } from 'firebase/firestore'
import { useRecoilValue } from 'recoil'
import { Workspace } from 'vidbase-types/workspace'
import { workspaceState } from '@/store/workspace'

const useWorkspace = (): [
  Workspace<Timestamp, DocumentReference> | undefined,
  boolean,
  Error | undefined
] => {
  const { workspace, loading, error } = useRecoilValue(workspaceState)
  return [workspace, loading, error]
}

export default useWorkspace
