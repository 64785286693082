import {
  CollectionReference,
  DocumentReference,
  setDoc,
  updateDoc,
  addDoc,
  getDoc,
  getDocs,
  deleteDoc,
  FirestoreDataConverter,
  serverTimestamp,
  WithFieldValue,
} from 'firebase/firestore'
import { Base } from 'vidbase-types/document'

export type MutationDocumentData<T extends Base> = WithFieldValue<
  Omit<T, 'id' | 'ref' | 'createdAt' | 'updatedAt'>
>

export type MutationDocumentDataWithTimestamp<T extends Base> = WithFieldValue<
  Omit<T, 'id' | 'ref'>
>

export const getDocument = async <T extends Base>(
  ref: DocumentReference,
  converter: FirestoreDataConverter<T>
): Promise<T | undefined> => {
  const snapshot = await getDoc(ref.withConverter<T>(converter))
  if (!snapshot.exists()) {
    return undefined
  }

  const document = snapshot.data()!
  return document
}

export const getDocuments = async <T extends Base>(
  ref: CollectionReference,
  converter: FirestoreDataConverter<T>
): Promise<T[]> => {
  const snapshot = await getDocs(ref.withConverter<T>(converter))
  const documents = snapshot.docs.map((doc) => doc.data())
  return documents
}

export const addDocument = async <T extends Base>(
  ref: CollectionReference,
  data: MutationDocumentData<T>
) => {
  const result = await addDoc(ref, {
    ...(data as object),
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  })
  return result
}

export const createDocument = async <T extends Base>(
  ref: DocumentReference,
  data: MutationDocumentData<T>
) => {
  const result = await setDoc(ref, {
    ...(data as object),
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  })
  return result
}

export const partialUpdateDocument = async <T extends Base>(
  ref: DocumentReference,
  data: Partial<MutationDocumentData<T>>
) => {
  const result = await updateDoc(ref, {
    ...data,
    updatedAt: serverTimestamp(),
  })
  return result
}

export const deleteDocument = async (ref: DocumentReference) => {
  const result = await deleteDoc(ref)
  return result
}
