import { User as AuthUser } from 'firebase/auth'
import useAuthUser from '@/hooks/auth/useAuthUser'
import useSyncAuthUser from '@/hooks/auth/useSyncAuthUser'

interface AuthUserProviderProps {
  children?: ({ authUser }: { authUser?: AuthUser }) => React.ReactNode
}

const AuthUserProvider = ({ children }: AuthUserProviderProps) => {
  const [authUser] = useAuthUser()
  useSyncAuthUser()

  return <>{children && children({ authUser })}</>
}

export default AuthUserProvider
